import React from 'react';

const MoneyHand: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4H24V17H22V6H2V14H9V16H0V4Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 12C15 9.23872 17.2387 7 20 7H21V15.5C21 20.1943 17.1943 24 12.5 24C7.80572 24 4 20.1943 4 15.5V14H6V15.5C6 19.0897 8.91028 22 12.5 22C16.0897 22 19 19.0897 19 15.5V9.17074C17.8349 9.58262 17 10.6939 17 12V15H16C14.3433 15 13 16.3433 13 18V19H11V18C11 15.5812 12.7178 13.5633 15 13.1V12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10Z"
      />
      <path d="M12 11C12.5523 11 13 10.5523 13 10C13 9.44772 12.5523 9 12 9C11.4477 9 11 9.44772 11 10C11 10.5523 11.4477 11 12 11Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C4 1.79072 5.79072 0 8 0H16C18.2093 0 20 1.79072 20 4V6H18V4C18 2.89528 17.1047 2 16 2H8C6.89528 2 6 2.89528 6 4V6H4V4Z"
      />
    </svg>
  );
};

export default MoneyHand;
